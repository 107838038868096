<template>
	<div class="schemeLibrary">
		<div class="container">
			<h4>方案管理</h4>
			<el-link :underline="false" type="primary" @click="showPri()" v-if="sysMemberclass==false">新建方案</el-link>
			<el-table :data="list" :row-style="{ height: '50px' }" stripe :cell-style="{ textAlign: 'center' }" border>
				<el-table-column prop="id" label="方案编号"></el-table-column>
				<el-table-column prop="updateTime" label="修改时间"></el-table-column>
				<el-table-column prop="planName" label="创建人"></el-table-column>
				<el-table-column prop="state" label="状态"></el-table-column>
				<el-table-column label="操作">
					<!-- <template slot-scope="scope"> -->
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="check">查看</el-link>
						<el-link type="primary" :underline="false" @click="edit(scope.row)">编辑</el-link>
						<el-link type="primary" :underline="false" @click="del(scope.row)">删除</el-link>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import * as categorys from "@/api/categorys";
	import * as lifePlans from "@/api/lifeplans";
	export default {
		name: "lifePlans",
		data() {
			return {
				schemeList: [{
						num: "NCP_20220316_006407",
						time: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						time: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
				],
				multipleSelection: [], // 列表checkbox选中的值
				tableKey: 0,
				list: null,
				total: 0,
				isRouterAlive: false,
				listLoading: true,
				listQuery: {
					// 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					mobileTel: "", // 手机号
					planName: "", // 方案名称
				},
				sysMemberclass: false,
			};
		},
		activated() {
			this.getGategorys();
			this.getList();
		},
		created(){
			if (JSON.parse(localStorage.getItem("stuInfo")).syS_MemberClass == "2") {
				console.log('success');
				this.sysMemberclass=true;
			}else{
				this.sysMemberclass=false;
			}
		},
		methods: {
			check() {
				console.log("查看");
			},
			showPri() {
				this.$router.push("/careernewScheme");
			},
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				});
			},
			edit(row) {
				//console.log(row.id);
				var rowid = row.id;
				//carnewscheme
				this.$router.push({
					path: "/carnewscheme",
					query: {
						rowid: rowid
					}
				});
			},
			del(rows) {
				this.multipleSelection = [];
				this.multipleSelection.push(rows);
				console.log(this.multipleSelection);
				this.$confirm("是否删除?", "提示")
					.then(() => {
						lifePlans
							.del(this.multipleSelection.map((u) => u.id))
							.then((res) => {
								console.log(res);
								if (res.code == 200) {
									this.$notify({
										titl: "成功",
										message: "删除成功",
										type: "success",
										duration: 2000,
									});
									this.multipleSelection.forEach((row) => {
										const index = this.list.indexOf(row);
										this.list.splice(index, 1);
									});
								} else {
									this.$message.error(res.message);
								}
							})
							.catch((res) => {
								console.log(res);
							});
					})
					.catch(() => {});
			},
			getGategorys() {
				let listQuery = {
					page: 1,
					limit: 9999,
				};
				console.log("getcategorys");
				categorys.getList(listQuery).then((res) => {
					this.gategoryList = res.data;
					console.log("getcategorys2");
				});
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				if (!dicobject) {
					console.log("dicobject is null");
					return "";
				} else {
					return dicobject.name;
				}
			},
			getList() {
				this.list = [];
				this.listLoading = true;
				this.listQuery.mobileTel = JSON.parse(
					localStorage.getItem("stuInfo")
				).mobileTel;
				var thelist = [];
				thelist.push(this.listQuery);
				console.log(this.listQuery);
				lifePlans.getList(this.listQuery).then((response) => {
					let list1 = response.data;
					console.log(list1);
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];
						//console.log(o);
						// if(o.mobileTel==this.listQuery.mobileTel)
						// {

						// }
						this.list.push(o);
					}
					//this.listLoading = true
					//lifePlans.getList(this.listQuery).then(response => {
					//  this.list = response.data
					this.total = response.count;
					this.listLoading = false;
					console.log(this.list);
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.schemeLibrary {
		padding: 15px;
		height: calc(100% - 30px);

		.container {
			height: 100%;
			background-color: white;

			h4 {
				background-color: white;
				padding: 10px 20px;
				border-bottom: 1px solid #eee;
				margin: 0;
			}

			.el-table {
				height: calc(100% - 60px);
				width: calc(100% - 20px);
				margin: 10px;

				.el-link {
					padding: 0 10px;
				}
			}
		}
	}
</style>

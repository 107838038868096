import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/lifePlans/load',
    method: 'get',
    params
  })
}



export function getLifePlan(id) {
  return request({
    url: '/lifePlans/getLifePlan',
    method: 'get',
    params: {id:id}
  })
}

export function send(id) {
  return request({
    url: '/lifePlans/Send',
    method: 'get',
    params: {id:id}
  })
}

export function loadForRole(roleId) {
  return request({
    url: '/lifePlans/loadForRole',
    method: 'get',
    params: { appId: '', firstId: roleId }
  })
}

export function add(data) {
  return request({
    url: '/lifePlans/add',
    method: 'post',
    data
  })
}

export function addNew(data){
  return request({
    url:'/lifePlans/memberAddNew',
    method:'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/lifePlans/update',
    method: 'post',
    data
  })
}

export function updateNew(data) {
  return request({
    url: '/lifePlans/memberUpdateNew',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/lifePlans/memberdelete',
    method: 'post',
    data
  })
}

